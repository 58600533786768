<template>
  <div class="account-activation-container">
    <van-form validate-first ref="loginForm" :show-error="false">
      <div class="label">姓名</div>
      <van-field
        name="username"
        v-model="username"
        clearable
        placeholder="姓名"
        :rules="[{ required: true, message: '请填写姓名' }]"
      />
      <div class="label">手机号</div>
      <van-field
        name="telephone"
        v-model="telephone"
        clearable
        type="tel"
        placeholder="手机号"
        :rules="[
          { required: true, message: '请填写手机号' },
          { validator: validateMobile, message: '请输入正确的手机号' },
        ]"
      />
      <div class="label">验证码</div>
      <van-field
        name="sms"
        v-model="sms"
        clearable
        placeholder="请输入短信验证码"
        :rules="[{ required: true, message: '请输入短信验证码' }]"
      >
        <template #button>
          <span class="code1" v-if="!numVisible" @click="getTelCode">
            获取验证码
          </span>
          <span class="code2" v-else>{{ num }}后重新发送</span>
        </template>
      </van-field>
      <div class="agreement-list">
        <van-checkbox v-model="checked" icon-size="16px">
          我已阅读并同意<a
            class="link agreement-link"
            v-for="item in agreementList"
            :key="item.title"
            @click="handleShowAgreement(item.title)"
            >《{{ item.title }}》</a
          >
        </van-checkbox>
      </div>
      <div style="margin: 16px;">
        <van-button :disabled="subDisabled" block type="info" @click="onSubmit">
          提交
        </van-button>
      </div>
    </van-form>
    <Agreement v-model="showAgreement" :title="curAgreementTitle" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { Toast, Dialog } from 'vant'
  import api from '@/api/passport'
  import Agreement from '@/components/Agreement/index.vue'
  import { validateMobileNumber, validateEmail } from '@/utils/validate'

  export default {
    components: { Agreement },
    data() {
      this.agreementList = [
        {
          title: '服务协议',
        },
        {
          title: '隐私协议',
        },
        {
          title: '证书服务协议',
        },
        {
          title: '易云章企业与个人服务协议',
        },
      ]
      return {
        username: null,
        telephone: null,
        sms: null,
        numVisible: false,
        num: 60,
        getTelCodeTimer: null,
        checked: false,
        showAgreement: false,
        curAgreementTitle: '服务协议',
      }
    },
    computed: {
      ...mapState({
        systemInfo: state => state.system,
      }),
      subDisabled() {
        if (this.username && this.telephone && this.sms && this.checked) {
          return false
        }
        return true
      },
    },
    mounted() {},
    methods: {
      // 验证手机号
      validateMobile(val) {
        return validateMobileNumber(val)
      },
      // 验证表单项
      async validateField(formName, name) {
        let isPassed = true
        try {
          await this.resetValidation(formName, name)
          await this.$refs[formName].validate(name)
        } catch (error) {
          isPassed = false
          if (error) {
            this.$toast(error)
          }
        }
        return isPassed
      },
      // 重置表单项的验证提示
      async resetValidation(formName, name) {
        try {
          await this.$refs[formName].resetValidation(name)
        } catch (error) {
          if (error) {
            this.$toast(error)
          }
        }
      },
      async onSubmit() {
        try {
          const model = {
            account: this.telephone,
            name: this.username,
            code: this.sms,
          }
          await api
            .addUserBasicInfo(model)
            .then(res => {
              this.$store.dispatch('fetchUserInfo')
              this.$router.push({
                name: 'List',
              })
            })
            .catch(err => {
              this.$toast(err)
            })
        } catch (error) {
          console.error(error)
        }
      },
      handleShowAgreement(title) {
        this.showAgreement = true
        this.curAgreementTitle = title
      },
      // 获取手机验证码
      async getTelCode() {
        try {
          const isPassed = await this.validateField('loginForm', 'telephone')
          if (!isPassed) return
          // 在倒计时直接跳出
          if (this.getTelCodeTimer) return
          const model = {
            telephone: this.telephone,
            type: 16, // 16 验证码登录
            systemId: this.systemInfo.systemId,
          }
          await api.getTelCode(model)
          this.changeTelCodeButtonText()
          this.$toast({
            message: '验证码发送成功，请注意查收',
          })
        } catch (error) {
          console.error(error)
        }
      },
      // 修改获取验证码按钮文本
      changeTelCodeButtonText() {
        const defaultCountDown = this.num
        this.numVisible = true
        this.getTelCodeTimer = setInterval(() => {
          if (this.num === 0) {
            this.numVisible = false
            clearInterval(this.getTelCodeTimer)
            this.getTelCodeTimer = null
            this.num = defaultCountDown
          } else {
            this.num -= 1
          }
        }, 1000)
      },
    },
  }
</script>

<style lang="less" scoped>
  .account-activation-container {
    .label {
      font-size: 16px;
      color: #111a34;
      padding: 16px 16px 4px;
    }
    .code1 {
      color: #1676ff;
    }
    .code2 {
      color: #c1c4cd;
    }
    .agreement-list {
      padding: 16px;
      margin-top: 8px;
      font-size: 12px;
      line-height: 22px;
      font-weight: 400;
      word-break: break-all;
      .van-checkbox {
        align-items: flex-start;
        .van-checkbox__label {
          margin-top: -1px;
        }
      }
    }
    .footer-btn {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      box-shadow: 0 -0.16rem 0.26667rem 0 rgb(230 234 237 / 30%);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 12px;
      .btn-large {
        width: 100%;
        color: #fff;
        background: linear-gradient(90deg, #ff8068, #f46045);
        border: none;
        border-radius: 4px;
      }
    }
  }
</style>
